.payment-success-card {
    max-width: 500px;
    max-height: 250px;
    margin-top: 12%;
    padding: 40px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 8px;
}

.payment-success-title {
    color: #4BB543; /* Success green color */
    margin-bottom: 20px;
}

.payment-success-text {
    color: #333;
    margin-bottom: 30px;
}

.payment-success-button {
    background-color: #4BB543; /* Success green color */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.payment-success-button:hover {
    background-color: #409c39; /* Darker green on hover */
}