* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg);
}

a {
    color: unset;
    text-decoration: none;
}

.gradient__bg {

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 15% 25%, rgb(109, 119, 129) 0%, rgb(16, 28, 48) 100%);
    
    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 15% 25%, rgb(109, 119, 129) 0%, rgb(16, 28, 48) 100%);
    
    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 15% 25%, rgb(109, 119, 129) 0%, rgb(16, 28, 48) 100%);
    
    /* ie 10+ */
    background:-ms-radial-gradient(circle at 15% 25%, rgb(109, 119, 129) 0%, rgb(16, 28, 48) 100%);
    
    /* global 92%+ browsers support */
    background:radial-gradient(circle at 15% 25%, rgb(109, 119, 129) 0%, rgb(16, 28, 48) 100%);
}

.gradient__text {
    background:var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.return-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #40788a; /* Success green color */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 100;
}

.return-button:hover {
  background-color: #39679c; /* Darker green on hover */
}

.play-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #408a61; /* Success green color */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 100;
}

.play-button:hover {
  background-color: #2ac057; /* Darker green on hover */
}


 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  /* Ipads */
  @media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }

    .section__margin {
        margin: 4rem;
    }
  }

  /* Iphones */
  @media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }

    .section__margin {
        margin: 4rem 2rem;
    }
  }