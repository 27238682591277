.game-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh; /* Use full screen height */
    padding: 20px;
  }
  
  .avatar {
    width: 350px;
    height: 350px;
    min-width: 350px;
    min-height: 350px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .microphone {
    cursor: pointer;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid black;
    margin: 15px;
    position: relative;
    z-index: 10;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/microphone.png');
  }
  
  .circle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }

  .fake-box {
      margin-top: 10px;
      text-align: center;
      visibility: hidden;
  }

  .user-avatar {
    background-color: lightblue;
    
    position: relative;
    z-index: 10;
  }

  .victor {
    background-image: url('../../assets/character1.png');
    transform: scaleX(-1);
  }

  .ashton {
    background-image: url('../../assets/character2.png');
  }

  .maya {
    background-image: url('../../assets/character3.png');
  }

  .victoria {
    background-image: url('../../assets/character4.png');
  }

  .yasuke {
    background-image: url('../../assets/character5.png');
    transform: scaleX(-1);
  }

  .akira {
    background-image: url('../../assets/character6.png');
  }

  .xavier {
    background-image: url('../../assets/character7.png');
  }

  .naomi {
    background-image: url('../../assets/character8.png');
  }

  .amber {
    background-image: url('../../assets/character9.png');
  }

  .sebastian {
    background-image: url('../../assets/character10.png');
    transform: scaleX(-1);
  }
  
  .ai-avatar {
    border: 4px solid black;
    position: relative;
    z-index: 10;
  }

  .musclecarsales, .customsellchuck {
    background-image: url('../../assets/boomer1.png');
  }

  .coldcalllandscape, .customsellemily {
    background-image: url('../../assets/landscape1.png');
  }

  .coldcallmassage, .customsellaria {
    background-image: url('../../assets/massage1.png');
  }

  .coldcallvet, .customselllucas {
    background-image: url('../../assets/veterinarian1.png');
  }

  .wallstgmt, .customsellmarcus {
    background-image: url('../../assets/wallst1.png');
    transform: scaleX(-1);
  }

  .wallstees, .customsellelizabeth{
    background-image: url('../../assets/wallst4.png');
  }

  .wallstffi, .customsellalexander {
    background-image: url('../../assets/wallst3.png');
  }

  .insurancelife, .customsellethan {
    background-image: url('../../assets/insurance1.png');
  }

  .insurancehealth, .customselllauren {
    background-image: url('../../assets/insurance3.png');
  }

  .insurancehome, .customselldavid {
    background-image: url('../../assets/insurance2.png');
  }

  .wolfstntii, .customselljack {
    background-image: url('../../assets/wolfst1.png');
  }

  .wolfstbgac, .customsellvivian {
    background-image: url('../../assets/wolfst2.png');
  }

  .wolfstqlt, .customsellalex {
    background-image: url('../../assets/wolfst3.png');
  }

  .portstrattom, .customselltom {
    background-image: url('../../assets/fin1.png');
  }

  .basicintdev, .customintclaire {
    background-image: url('../../assets/BasicInt1.png');
  }

  .basicintcoord, .customintmichael {
    background-image: url('../../assets/BasicInt2.png');
  }

  .basicintexec, .customintvanessa {
    background-image: url('../../assets/BasicInt3.png');
    transform: scaleX(-1);
  }

  .techinteng, .customintcarter {
    background-image: url('../../assets/engineer1.png');
  }

  .techintsoft, .customintjessica {
    background-image: url('../../assets/software1.png');
  }

  .techintsci, .custominthiroshi {
    background-image: url('../../assets/scientist1.png');
  }

  .createintwrite, .customintaudrey {
    background-image: url('../../assets/createint1.png');
  }

  .createintui, .customintgabby {
    background-image: url('../../assets/interview2.png');
  }

  .createintsocial, .customintgabriel {
    background-image: url('../../assets/createint2.png');
  }

  .leadintcoo, .customintjennifer {
    background-image: url('../../assets/interview4.png');
  }

  .leadintcfo, .customintkenji {
    background-image: url('../../assets/cfo.png');
  }

  .leadintceo, .customintrich {
    background-image: url('../../assets/aiCEO1.png');
  }

  .salarynegdev, .customnegthomas {
    background-image: url('../../assets/negotiation3.png');
    transform: scaleX(-1);
  }

  .salarynegproj, .customnegjulia {
    background-image: url('../../assets/negotiation1.png');
  }

  .salarynegdes, .customnegdaniel {
    background-image: url('../../assets/negotiation4.png');
  }

  .realestate, .customneglinda {
    background-image: url('../../assets/realestate1.png');
  }

  .investmentpitchurban, .customnegben {
    background-image: url('../../assets/pitch1.png');
  }

  .investmentpitchtreat, .customnegluna {
    background-image: url('../../assets/deal6.png');
  }

  .investmentpitchstem, .customnegrico {
    background-image: url('../../assets/pitch2.png');
  }

  .uspoliticsgreen, .customdebatediana {
    background-image: url('../../assets/uspolitics1.png');
  }

  .uspoliticsgun, .customdebatemike {
    background-image: url('../../assets/uspolitics2.png');
  }

  .uspoliticsubi, .customdebateava {
    background-image: url('../../assets/uspolitics3.png');
  }

  .customsellmateo {
    background-image: url('../../assets/sell1.png');
  }

  .customintadam {
    background-image: url('../../assets/interview5.png');
  }

  .customdebatenatalie {
    background-image: url('../../assets/debate1.png');
  }

  .customdebatejohnathan {
    background-image: url('../../assets/politician1.png');
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
    }
  }

  .pulse {
    animation: pulse-animation 1.5s infinite;
  }

  .siri-wave-container {
    flex: 1;
    width: 250px;
    height: 100px;
    flex-direction: row;
    margin: 4px;
  }

  .ai-siri-wave-container {
    transform: scaleX(-1.3); /* This flips the wave horizontally */
  }

  .user-siri-wave-container {
    transform: scaleX(1.3);
  }

  .invisible {
    display: none;
  }

  .speech-textbox{
    border: 3px solid #2c2c2c; /* Blue border */
    border-radius: 10px; /* Rounded corners */
    padding: 15px; /* Inner spacing */
    margin-top: 20px; /* Spacing from the element above */
    font-size: 21px;
    color: #000000; /* Text color */
    background-color: #ffffffce; /* Background color */
    max-width: 475px; /* Maximum width */
    word-wrap: break-word; /* Break long words */
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2); /* Soft shadow for depth */
    text-align: center; /* Center the text */
    max-height: 300px; /* Fixed maximum height */
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: thin; /* Makes scrollbar thinner (for supporting browsers) */
    scrollbar-color: #1a2a4f #e0e1dd; /* Custom scrollbar colors (for supporting browsers) */
  }

  .user-text-absolute {
    position: absolute;
    left: 70px; /* Adjust as needed */
    top: 65%;
  }

  .ai-text-absolute {
    position: absolute;
    right: 70px;
    top: 67%;
  }

  .feeling-textbox{
    border: 2px solid #2c2c2c;
    border-radius: 8px;
    padding: 8px;
    font-size: 15px;
    color: #cefffd;
    background-color: #a3a3a385;
    max-width: 475px; /* Maximum width */
    word-wrap: break-word; /* Break long words */
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
    text-align: center; 
    max-height: 300px;
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: thin; /* Makes scrollbar thinner (for supporting browsers) */
    scrollbar-color: #1a2a4f #e0e1dd; /* Custom scrollbar colors (for supporting browsers) */
  }

  .ai-feeling-absolute {
    position: absolute;
    right: 20px;
    top: 32%;
  }

  .reason-textbox{
    border: 2px solid #2c2c2c;
    border-radius: 8px;
    padding: 8px;
    font-size: 15px;
    color: #cefffd;
    background-color: #a3a3a385;
    max-width: 450px; /* Maximum width */
    word-wrap: break-word; /* Break long words */
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
    text-align: center; 
    max-height: 300px;
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: thin; /* Makes scrollbar thinner (for supporting browsers) */
    scrollbar-color: #1a2a4f #e0e1dd; /* Custom scrollbar colors (for supporting browsers) */
  }

  .ai-reason-absolute {
    position: absolute;
    left: 55%;
    top: 21%;
  }

  .suggestion-textbox{
    border: 2px solid #2c2c2c;
    border-radius: 8px;
    padding: 8px;
    font-size: 15px;
    color: #cefffd;
    background-color: #a3a3a385;
    max-width: 450px; /* Maximum width */
    word-wrap: break-word; /* Break long words */
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
    text-align: center; 
    max-height: 300px;
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: thin; /* Makes scrollbar thinner (for supporting browsers) */
    scrollbar-color: #1a2a4f #e0e1dd; /* Custom scrollbar colors (for supporting browsers) */
  }

  .ai-suggestion-absolute {
    position: absolute;
    left: 18%;
    top: 21%;
  }

  .ai-feeling-animation {
    animation: fadeIn 2.5s ease-in-out forwards;
  }

  .ai-reason-animation {
    animation: fadeIn 2.5s ease-in-out forwards;
  }

  .ai-suggestion-animation {
    animation: fadeIn 2.5s ease-in-out forwards;
  }


  @keyframes fadeInOut {
    0% { opacity: 0; }
    25% { opacity: 1; }
    75% { opacity: 1; }
    100% { opacity: 0; }
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  .progress-bar-background {
    position: absolute;
    left: 10%;
    top: 2%;
    width: 80%;
    background-color: #e0e0e0;
    border-radius: 8px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }

  .progress-bar-fill {
    height: 20px;
    background-color: #4caf50;
    border-radius: 8px;
  }
  
  /*
  .progress-bar-increase .progress-bar-color {
    animation: increaseAnimation 3s ease-in-out forwards;
  }

  .progress-bar-decrease .progress-bar-color {
    animation: decreaseAnimation 3s ease-in-out forwards;
  }

  @keyframes increaseAnimation {
    0% { background-color: #000000; }
    100% { background-color: #4caf50; }
  }

  @keyframes decreaseAnimation {
    0% { background-color: #ff4444; }
    25% { background-color: #ff2222; }
    50% { background-color: #ff4444; }
    75% { background-color: #ff2222; }
    100% { background-color: #ff4444; }
  }
*/

  .settings-button {
    position: absolute;
    right: 5%; 
    top: 1.8%;
    background-color: transparent; 
    border: none; 
    cursor: pointer; 
    font-size: 24px;
    color: #c7c7c7;
  }

  .settings-button:hover {
    color: #757575; /* Darker color on hover */
  }

  .home-button {
    position: absolute;
    right: 2%; /* Adjust as needed */
    top: 1.6%;
    background-color: transparent; /* Transparent background */
    border: none; /* No border */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 24px; /* Size of the gear icon */
    color: #c7c7c7; /* Color of the icon */
  }

  .home-button:hover {
    color: #757575; /* Darker color on hover */
  }

  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .popup-content {
    background: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%; /* Cover most of the screen */
    max-width: 600px; /* Maximum width */
    text-align: center; /* Center the content */
    padding: 30px;
  }

  .button-group {
    margin-bottom: 20px; /* Space between buttons and resume button */
  }

  .difficulty-button, .resume-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }

  .difficulty-button:hover, .resume-button:hover {
      background-color: #45a049; /* Darker green on hover */
      transform: translateY(-2px); /* Slight lift effect */
  }

  .resume-button {
      background-color: #008CBA; /* Blue background for distinction */
      font-weight: bold;
  }

  .x-button {
    position: relative; /* Position the button absolutely relative to .pop-content */
    top: 0px; 
    right: 0px;
    background-color: #f8665c; /* Red color for visibility and distinction */
    color: white; /* White text color */
    font-weight: bold;
    font-size: 16px; /* Larger font size for better readability */
    border: none; 
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover effect */
  }

  .x-button:hover {
    background-color: #d32f2f; /* Slightly darker color on hover */
    transform: scale(1.1); /* Slight scale up on hover */
  }

  .difficulty-option {
    text-align: center; /* Center-align text */
    margin-bottom: 15px; /* Spacing between each option */
  }

  .difficulty-description {
    color: #666; /* A lighter, less prominent color */
    font-size: 0.9em; /* Slightly smaller than the main text */
    margin-top: 5px; /* Spacing between button and description */
  }

  .level {
    position: absolute;
    left: 2%;
    top: 1.25%;
    background-color: #5bb2dd;
    border: 2px solid #333; 
    padding: 10px;
    margin: 10px 0;
    border-radius: 8px;
    font-size: 1em;
    color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-weight: bold;
  }

  /* Container for the countdown timer */
  .countdown-timer {
    position: absolute;
    top:28%;
    left:38%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1818184d;
    border: 2px solid #ddd;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80px;
    margin: 0 auto;
    font-size: 2em;
    color: #e9e9e9;
  }

  .unclickable {
    cursor: default;
    opacity: 0.5;
  }


  .win-pop-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 500px;
    width: 90%;
  }
  
  .close-button {
    float: right;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .celebration-message h2 {
    color: #4CAF50; /* Greenish color for success */
    margin-bottom: 10px;
  }
  
  .celebration-message p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .continue-button {
    background-color: #4CAF50; /* Greenish color for success */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .continue-button:hover {
    background-color: #367c39; /* Darker green */
  }

  .audio-playback {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .prompt-start {
    position: absolute;
    top: 34%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5em;
    color: #e7e7e7;
    z-index: 100;
  }

   /* Ipads */
  @media screen and (max-width: 1450px) {
    .avatar {
      width: 250px;
      height: 250px;
      min-width: 250px;
      min-height: 250px;
    }

    .microphone {
      width: 200px;
      height: 200px;
    }
  }

  @media (max-width: 1100px) {
    .game-container {
      flex-direction: column; /* Stack items vertically */
    }

    .circle-container {
      flex-direction: row;
    }

    .microphone {
      flex-direction: row;
      align-items: center;
    }

    .user-fake-box {
      margin-top: 0;
      margin-left: 320px;  /*Space between text and circle */
      text-align: left;
    }

    .ai-fake-box {
      margin-top: 0;
      margin-right: 320px;
      text-align: left;
    }

    .user-siri-wave-container {
      transform: rotate(55deg) scaleX(0.9) translate(-40px, 90px);
      transform-origin: center;
    }

    .ai-siri-wave-container {
      transform: rotate(-125deg) scaleX(0.9) translate(-60px, 90px);
      transform-origin: center;
    }

    .avatar {
      width: 230px;
      height: 230px;
      min-width: 230px;
      min-height: 230px;
    }

    .microphone {
      width: 180px;
      height: 180px;
      min-width: 180px;
      min-height: 180px;
    }

    .user-text-absolute {
      position: absolute;
      left: auto;
      right: 20px;
      top: 4%;
    }
  
    .ai-text-absolute {
      position: absolute;
      left: 20px;
      top: 72%;
    }

    .progress-bar-background {
      top: 50%;
    }

    .settings-button {
      right: 8%;
    }

    .ai-reason-absolute {
      position: absolute;
      right: 30%;
      top: 30%;
      min-width: 200px;
    }

    .ai-suggestion-absolute {
      position: absolute;
      right: 5%;
      top: 30%;
      max-width: 200px;
    }

    .ai-feeling-absolute {
      position: absolute;
      right: 15%;
      top: 70%;
    }
    .countdown-timer {
      top: 40%;
      left: 24%;
    }

    .audio-playback {
      position: absolute;
      top: 6%;
      left: 65%;
      transform: translate(-50%, -50%);
    }
  }


@media screen and (max-height: 800px) and (max-width: 1100px) {
  .avatar {
    width: 180px;
    height: 180px;
    min-width: 180px;
    min-height: 180px;
  }

  .microphone {
    width: 150px;
    height: 150px;
    min-width: 150px;
    min-height: 150px;
  }

  .user-siri-wave-container {
      
    transform: rotate(55deg) scaleX(0.9) translate(-40px, 80px);
    transform-origin: center;
  }

  .ai-siri-wave-container {
    transform: rotate(-130deg) scaleX(0.9) translate(-60px, 80px);
    transform-origin: center;
  }

  .user-fake-box {
    margin-left: 280px;  /*Space between text and circle */
  }

  .ai-fake-box {
    margin-right: 280px;
  }

  .speech-textbox {
    max-height: 200px;
  }
}


@media screen and (max-height: 550px) and (max-width: 1100px) {
  .avatar {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
  }

  .microphone{
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
  }

  .user-siri-wave-container {
      
    transform: rotate(36deg) scaleX(0.6) translate(-55px, 110px);
    transform-origin: center;
  }

  .ai-siri-wave-container {
    transform: rotate(-145deg) scaleX(0.6) translate(-80px, 100px);
    transform-origin: center;
  }

  .user-avatar{
    transform: translate(0px, 100px);
  }

  .ai-avatar{
    transform: translate(-20px, -90px);
  }

  .speech-textbox {
    max-height: 125px;
  }
}

@media screen and (max-width: 800px) {
  .speech-textbox {
    max-width: 350px;
  }
}
