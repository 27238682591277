.select-char {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    min-height: 100vh;
}

.select-char h1 {
    font-size: 2.5rem; /* Smaller than h2 for hierarchy */
    text-align: center;
    background-color: #252525; /* Very light blue for scenarios */
    border: 4px solid #000000; /* Adds a line after each item */
    border-radius: 15px; /* Rounded corners for the menu */
    opacity: 0.8;
    padding: 8px 0; /* Adds top and bottom padding */
    color: rgb(255, 255, 255);
    padding-left: 20px;
    padding-right: 20px;
    
}

.choose-char-container {
    text-align: center;
    padding: 20px;
    display: flex;
    min-height: 100vh;
    padding-left: 20px;
    justify-content: center;
    font-family: "Times New Roman", Times, serif;
}

.chars-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Space between characters */
    padding: 22px;
}


.chars-container p {
    font-size: 1.2rem; /* Smaller than h2 for hierarchy */
    margin-bottom: 10px;
    text-align: center;
    background-color: #252525; /* Very light blue for scenarios */
    border: 3px solid #000000; /* Adds a line after each item */
    border-radius: 12px; /* Rounded corners for the menu */
    opacity: 0.8;
    padding: 10px 0; /* Adds top and bottom padding */
    color: rgb(255, 255, 255);
    font-weight: bold;
}

.chars-container h3 {
    font-size: .85rem; /* Smaller than h2 for hierarchy */
    margin-bottom: 4px;
    text-align: center;
    background-color: #252525; /* Very light blue for scenarios */
    border: 2px solid #000000; /* Adds a line after each item */
    border-radius: 12px; /* Rounded corners for the menu */
    opacity: 0.8;
    padding: 5px 0; /* Adds top and bottom padding */
    color: rgb(255, 255, 255);
    margin-left:10%;
    width: 80%;
}

.character {
    width: 300px;
    height: 300px;
    min-width: 300px;
    min-height: 300px;
    border-radius: 50%; /* Makes the div a circle */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 10px;
    margin-top: 10px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.character:hover {
    transform: scale(1.05);
}

.character img {
    width: 100%; /* Ensures image covers the character div */
    height: auto;
    display: block; /* Remove extra space below the image */
}

