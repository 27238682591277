.terms-card {
    max-width: 500px;
    max-height: 2400px;
    padding: 40px;
    margin-top: 5%;
    margin-right: 15px;
    margin-left: 15px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 8px;
}

.privacy-card {
    max-width: 500px;
    max-height: 1500px;
    padding: 40px;
    margin-top: 5%;
    margin-right: 15px;
    margin-left: 15px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 8px;
}


.terms-title {
    color: #416f8d;
    margin-bottom: 18px;
}

.terms-text {
    color: #333;
    margin-bottom: 14px;
}

.terms-header {
    color: #333;
    margin-top: 40px;
    margin-bottom: 18px;
    text-align: left;
}