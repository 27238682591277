.scen__format {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.scen__format-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 8rem;
}

.scen__format-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.scen__format-heading p {
    color: var(--color-subtext);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);

    margin-top: 2rem;
}

.scen__format-container {
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.arts-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin: 1rem;
}

.arts-title {
    flex: 1;
    max-width: 400px;
    margin-right: 2rem;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #fff;
    margin-bottom: 1rem;
    height: 3px;
}

.gradient-line {
    width: 50%;
    height: 3px;
    background: var(--gradient-bar); /* Ensure this variable is defined in your CSS */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 1rem; /* Space between the gradient line and the text */
}


.arts-text {
    margin-bottom: 1rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    color: var(--color-text);
}

.ceo {
    width: 580px;
    height: 550px;
}

@media screen and (max-width: 990px) {
    .scen__format {
        flex-direction: column;
    }

    .scen__format-heading {
        margin: 0 0 2rem 0;
    }

    .ceo {
        width: 440px;
        height: 410px;
    }
}


@media screen and (max-width: 550px) {
    .scen__format-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}