.container {
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    padding-left: 20px;
    justify-content: center;
}
.menu {
    width: 18%; /* Reduced width */
    max-height: 85vh; /* Max height */
    min-width: 350px;
    margin-right: 25px;
    overflow-y: auto;
    background-color: #282c2c;
    border: 4px solid #000000; /* Border for the menu */
    box-shadow: 2px 0 5px rgba(0,0,0,0.2);
    padding: 18px;
    margin-top: 20px; /* Top margin */
    margin-bottom: 20px; /* Bottom margin */
    border-radius: 12px; /* Rounded corners for the menu */
    opacity: 0.9;
}
.category {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    color: #54a6ff; /* Darker blue for category text */
    margin-bottom: 15px;
    border-bottom: 2px solid #b3d1ff; /* Blue border under category */
    padding-bottom: 5px;
}

.standard-button {
    background-color: #4CAF50; /* Green background */
    color: white; /* White text */
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 5px;
    margin-bottom: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.standard-button:hover {
    background-color: #45a049; /* Darker green on hover */
    transform: translateY(-2px); /* Slight lift effect */
}

.difficulty-options {
    display: flex;
    justify-content: center;
    gap: 20px; /* Spacing between each option */
    padding: 20px;
    font-family: Arial, sans-serif; /* Choose a font that matches your design */
  }
  
  .difficulty-options input[type="radio"] {
    display: none; /* Hide default radio button */
    
  }
  
  .difficulty-options label {
    display: inline-block;
    padding: 10px 20px;
    background-color: #bbbbbb;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s, border-color 0.3s;
    border: 2px solid #181818;
  }
  
  .difficulty-options input[type="radio"]:checked + label {
    background-color: #4CAF50;
    color: white;
  }
  
  .difficulty-options label:hover {
    background-color: #ddd;
  }

.diff-levels {
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    color: #afafaf; /* Darker blue for category text */
    margin-bottom: 15px;
    border-bottom: 1px solid #b3d1ff; /* Blue border under category */
    padding-bottom: 5px;
}

.scenario {
    display: flex;
    position: relative;
    align-items: center; /* Align items vertically in the center */
    justify-content: space-between; /* Space between text and image */
    background-color: #cecece; /* Very light blue for scenarios */
    border: 2px solid #54a6ff; /* Blue border for each scenario */
    border-radius: 10px;
    color: #001124;
    height: 150px;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}
.scenario:hover {
    transform: scale(1.05); /* Slightly scale up on hover */
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Shadow effect on hover */
}

.scenario-title {
    font-weight: bold;
    border-bottom: 2.5px solid #181818;
    font-size: 1.25em;
    margin-bottom: 6px; /* Space between the title and the description */
}

.scenario-details {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.scenario div {
    text-decoration: none; /* Removes underline from links */
    font-weight: bold;
    margin-left: 9px;
    font-size: 16px;
}

.scenario img {
    width: auto; /* Make the image take up the full width of the scenario box */
    height: 100%; /* Maintain aspect ratio */
    border-radius: 10px; /* Optional: rounded corners for the image */
}

.coming-soon-overlay {
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    bottom: 30%;
    background-color: rgba(0, 0, 0, 0.35); /* Semi-transparent black */
    border-radius: 12px;
    border: 2px solid #b13800;
    color: white; /* Text color */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px; /* Adjust as needed */
    font-weight: bold;
    text-align: center;
    z-index: 10; /* Ensure it's above other content */
    transform: rotate(-25deg);
}

/* Styling the scrollbar track (background) */
.menu::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Light grey background */
    border-radius: 12px; /* Rounded corners for the track */
}

/* Styling the scrollbar handle */
.menu::-webkit-scrollbar-thumb {
    background-color: #6b7f9e; /* Color of the scrollbar handle */
    border-radius: 12px; /* Rounded corners for the handle */
}

/* Optional: change the scrollbar width */
.menu::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

.detail-content::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Light grey background */
    border-radius: 12px; /* Rounded corners for the track */
    
}

/* Styling the scrollbar handle */
.detail-content::-webkit-scrollbar-thumb {
    background-color: #6b7f9e; /* Color of the scrollbar handle */
    border-radius: 12px; /* Rounded corners for the handle */
}

/* Optional: change the scrollbar width */
.detail-content::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

.pop-content::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Light grey background */
    border-radius: 12px; /* Rounded corners for the track */
    
}

/* Styling the scrollbar handle */
.pop-content::-webkit-scrollbar-thumb {
    background-color: #6b7f9e; /* Color of the scrollbar handle */
    border-radius: 12px; /* Rounded corners for the handle */
}

/* Optional: change the scrollbar width */
.pop-content::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

.process-content::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Light grey background */
    border-radius: 12px; /* Rounded corners for the track */
    
}

/* Styling the scrollbar handle */
.process-content::-webkit-scrollbar-thumb {
    background-color: #6b7f9e; /* Color of the scrollbar handle */
    border-radius: 12px; /* Rounded corners for the handle */
}

/* Optional: change the scrollbar width */
.process-content::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}


.detail-overlay {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.detail-content {
    display: flex;
    font-size: 1.1em;
    justify-content: flex-start;
    background-color: rgb(201, 201, 201);
    width: 80%;
    height: 80%;
    padding: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    overflow: auto;
    border-radius: 25px;
    border: 3px solid #000000;
}

.pop-content {
    position: relative;
    display: flex;
    font-size: 1.1em;
    justify-content: flex-start;
    background-color: rgb(201, 201, 201);
    width: 60%;
    height: 80%;
    padding: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    overflow: auto;
    border-radius: 25px;
    border: 3px solid #000000;
}

.pop-content img{
    width: 80%;
    height: 35%;
    min-width: 200px;
    min-height: 200px;
    border-radius: 20px;
    margin-right:100px;
    border: 4px solid #1f1f1f;
}

.detail-right {
    flex-direction: column;
    width: 70%;
    padding-left: 60px;
    text-align: left;
}   

.detail-right h1 {
    text-align: center;
}

.detail-right p{
    margin-bottom: 10px;
    margin-top: 5px;
}

.process-content {
    display: flex;
    font-size: 1.1em;
    justify-content: flex-start;
    background-color: rgb(201, 201, 201);
    width: 80%; /* Adjust as needed */
    max-width: 700px;
    height: 80%; /* Adjust as needed */
    padding: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    overflow: auto; /* In case content overflows */
    border-radius: 25px;
    border: 3px solid #000000;
}

.sales-process {
    width: 100%;
    max-width: 600px; /* Adjust as needed */
    margin: auto;
    padding: 16px;
}

.sales-process h1 {
    text-align: center;
    margin-bottom: 15px;
    font-family: 'Arial', sans-serif;
}

.step-number {
    background-color: rgb(68, 95, 168); /* Blue background for the step number */
    color: white;
    padding: 10px 15px;
    font-weight: bold;
    border-radius: 10px 10px 0 0;
}

.step-description {
    padding: 15px;
    background-color: white; /* White background for the description */
    border-radius: 0 0 10px 10px; /* Rounded bottom corners */
    font-size: 0.9em;
}

.step {
    border-radius: 10px;
    margin-bottom: 15px; /* Space between steps */
    overflow: hidden; /* Ensures the child border-radius is respected */
}

.desc {
    background-color: #1a1a1a;
    border: 3px solid #000000;
    border-radius: 12px;
    opacity: 0.8;
    padding: 10px 0;
    color: rgb(255, 255, 255);
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
}

.desc p {
    margin-left: 45px;
    margin-right: 45px;
    margin-bottom: 10px;
}

.detail-left {
    width: 20%; /* Half the width of the popup */
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    min-width:200px;
}

.detail-left p {
    margin-left: 1px;
    margin-right: 10px;
}

.detail-left img{
    width: 80%; /* Make the image take up the full width of the scenario box */
    height: 35%; /* Maintain aspect ratio */
    min-width: 200px;
    min-height: 200px;
    border-radius: 20px; /* Optional: rounded corners for the image */
    margin-right:100px;
    border: 4px solid #1f1f1f;
}

.backorplay {
    text-align: center;
    margin-top: 12px;
}

.chars {
    background-color: #252525; /* Very light blue for scenarios */
    border: 3px solid #000000; /* Adds a line after each item */
    border-radius: 12px; /* Rounded corners for the menu */
    opacity: 0.8;
    padding: 10px 5px; /* Adds top and bottom padding */
    margin-bottom: 3px;
    color: rgb(255, 255, 255);
}

.chars li {
    margin-bottom: 10px;
}

.detail-characteristics p{
    margin-bottom: 6px;
    margin-left: 10px;
    text-align: left;
}

.options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-top: 10px;
}

.options p {
    max-width:290px;
    margin-right: 10px;
}

.options ul {
    border-bottom: 3px solid #8b8b8b;
    margin-top: 8px;
    margin-left: 15px;
    margin-right: 40px;
    max-width: 230px;
}

.options h3 {
    margin-left: 20px;
    margin-bottom: 6px;
}

.options img {
    width: 200px;
    height: 200px;
    margin: 15px;
    cursor: pointer;
    border-radius: 15px;
    border: 3px solid #1f1f1f;
}

.highlighted-border {
    border: 40px solid #0e290f; /* Change the color and size as needed */
    box-shadow: 0 0 40px #4caf50; /* Optional: add a shadow for more emphasis */
}

.pros {
    color: #337a35;
}

.pros li {
    margin-bottom: 10px;
}

.cons {
    color: rgb(119, 30, 30);
}

.cons li {
    margin-bottom: 10px;
}

.thumbnail-hover .thumbnail-link {
    position: relative;
    display: inline-block; /* Or 'block' depending on your layout */
  }
  
  .thumbnail-hover .thumbnail {
    display: none;
    position: absolute;
    width: 300px; /* Adjust as needed */
    height: auto;
    top:5%;
    left: 30%;
    border: 3px solid #2e7ea3;
    background-color: #525252;
    border-radius: 10px;
    z-index: 100;
  }
  
  .thumbnail-hover .thumbnail-link:hover .thumbnail {
    display: block;
  }


  .input-form {
    max-width: 700px;
    margin: 20px auto;
    padding: 20px;
    background: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.input-form h3 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.form-group input[type="text"],
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-group textarea {
    height: 100px;
    resize: vertical;
}

.form-group input[type="text"]:focus,
.form-group textarea:focus {
    border-color: #4caf50;
    outline: none;
}

.margin-bot {
    margin-bottom: 20px;
}

.difficulty-indicators {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.dot {
    height: 10px;
    width: 10px;
    background-color: #bbb; /* Grey by default */
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
}

.dot.won {
    background-color: #4CAF50; /* Green when won */
}

@media (max-width: 1000px) {
    .detail-content {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .detail-left, .detail-right {
        width: 90%; /* Full width in a vertical layout */
        justify-content: center;
        align-items: center;
        margin-left: 0px;
        text-align: center;
    }

    .detail-left img {
        max-width: 200px;
        max-height: 200px;
        margin-right:0px;
    }

    /* Swap the order of characteristics and description */
    .detail-characteristics {
        order: 2;
        display: flex;
        flex-direction: column;
        align-items: center; /* Aligns items to the start of the flex container */
        text-align: center; /* Center-aligns the text within each item */
        width: 100%; /* Adjust as necessary */
        margin: 0 auto; /* Center the container */
    }

    .detail-right {
        order: 1;
        padding-left: 0; /* Remove left padding in vertical layout */
        
    }
}