.pricing-card {
    background-color: #262626;
    border-radius: 15px;
    color: #fff;
    width: 45%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin: 5%;
    max-width:500px;
    max-height: 370px;
}

.plan-name {
    margin-top: 0;
    color: #a9a9a9;
}

.price {
    font-size: 3em;
    margin: 10px 0;
}

.dollar-sign {
    font-size: 0.7em;
    vertical-align: top;
}

.per-month {
    font-size: 0.5em;
    vertical-align: bottom;
}

.divider {
    border: none;
    height: 2px;
    background-color: #007bff;
    margin: 20px 0;
}

.features {
    list-style: none;
    padding: 0;
    text-align: left;
}

.features li {
    margin: 10px 0;
    font-size: 0.9em;
}

.checkmark {
    color: #007bff;
    margin-right: 5px;
}

.subscribe-button {
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    color: white;
    font-size: 1em;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
}

.subscribe-button:hover {
    background-color: #0056b3;
}

@media (max-width: 600px) {
    .pricing-card {
        width: 80%;
    }
}