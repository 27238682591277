.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Space between cards */
}

.profile-card {
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 1200px;
    padding: 20px;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    align-items: center;
    gap: 15px; /* Space between elements inside the card */
}

.profile-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
}

.user-info, .report-content, .subscription-content {
    width: 100%;
    display: flex;
    flex-direction: column; /* Adjust to row if needed for specific sections */
    align-items: center;
    gap: 5px;
}

.personalized-report {
    max-width: 500px;
}

.user-email {
    font-size: 18px;
    color: #40788a;
}

.profile-title {
    font-size: 22px;
    color: #416f8d;
    margin-bottom: 15px;
}

.user-details-card {
    display: flex;
    align-items: flex-start;
    min-width: 500px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 65px;
}

.user-image-section {
    position: relative;
    margin-right: 20px;
}

.user-profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 1px;
    cursor: pointer;
}

.change-photo-button {
    position: absolute;
    bottom: -5px;
    right: 8px;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.save-button {
    position: relative;
    max-width: 100px;
    margin-top: 15px;
    align-self: center;
    background-color: #40788a;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.save-button:hover {
    background-color: #39679c;
}

.user-info-section {
    display: flex;
    flex-direction: column;
    width: 100%;    
}

.user-detail {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e1e1e1;
    padding: 10px 0;
}

.user-detail-title {
    color: #333;
    font-weight: 600;
}

.user-detail-content {
    color: #666;
}

.subscription-card .subscription-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 20px;
  }

  .subscribe-button {
    max-width: 200px;
  }

  .sub-title {
    margin-bottom: 15px;
    color: #333;
  }

  .subscription-status-container {
    padding: 4px;
    border-radius: 6px;
    margin: 2px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    border: 2px solid #e1e1e1;
}

.subscription-status {
    font-size: 1.2rem;
    font-weight: bold;
    color: #4CAF50;
    margin-bottom: 5px;
}

.subscription-details {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
}

.sub-option-card {
    background-color: #262626;
    border-radius: 15px;
    color: #fff;
    width: 100%;
    min-width: 300px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin: 5%;
    max-width:500px;
    max-height: 370px;
}

.membership-status-badge {
    display: inline-block;
    padding: 5px 20px;
    background-color: #4CAF50;
    color: #fff;
    font-size: 20px;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    min-width: 120px;
    text-align: center;
}

.membership-status-text {
    font-size: 14px;
    color: #666;
    margin: 10px 0;
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .subscription-status-container {
        flex-direction: column;
    }

}