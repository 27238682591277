.gpt3__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar);
}

.img1 {
    margin-bottom: 20px;
}


@media screen and (max-width: 1700px) {
    .gpt3__cta {
        flex-direction: column;
        margin-top: 40px;
    }

}

@media screen and (max-width: 1000px) {
    .gpt3__cta img {
        transform: scale(0.8);
    }
}

@media screen and (max-width: 700px) {
    .gpt3__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .gpt3__cta img {
        transform: scale(0.6);
    }
}