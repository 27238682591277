.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navbar-links_logo {
    margin-right: 2rem;
}

.navbar-links_logo img {
    width: 95px;
    height: 90px;
}

.navbar-links_container {
    display: flex;
    flex-direction: row;
}

.navbar-sign {
    position: relative; /* Parent positioning context for the dropdown */
    display: flex; /* Aligns content properly */
    justify-content: flex-end;
    align-items: center;
}

.navbar-sign img {
    cursor: pointer;
    
}



.navbar-links_container p,
.navbar-sign p,
.navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.navbar-sign button,
.navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #fc3c11;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}

.profile-dropdown {
    position: absolute;
    top: 95px; /* Adjust based on the size of the profile image */
    left: 50%;
    border: none; /* Border for dropdown */
    border-radius: 4px; /* Rounded corners for dropdown */
    width: 110px; /* Set a fixed width for dropdown */
    transform: translateX(-42%);
    z-index: 100; /* Ensure dropdown is above other content */
}

.profile-dropdown button {
    display: block;
    width: 85%;
    margin-bottom: 6px;
    padding: 5px 10px;
    border: 1px solid #fff8f0;
    background-color: #fc3c11af; /* Light background for the dropdown */
    text-align: center;
    color: #ffffff;
    font-size: 1em;
    cursor: pointer;
}

.profile-dropdown button:hover {
    background-color: #f5f5f5; /* Color change on hover */
}

.navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.navbar-menu svg {
    cursor: pointer;
}

.navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.navbar-menu_container p {
    margin: 1rem 0;
}

.navbar-menu_container-sign {
    display: none;
}

.sign-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: rgb(191, 193, 196);
    padding: 15px;
    border-radius: 8px;
    width: 300px;
    
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

  .sign-popup h2 {
    margin-bottom: 20px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 20px;
    cursor: pointer;
  }

  .sign-popup input[type="email"] {
    width: calc(100% - 20px); /* Full width minus padding */
    padding: 10px;
    margin-bottom: 10px;
    border: 1.8px solid #4d4d4d; /* Light grey border */
    border-radius: 4px; /* Rounded corners */
  }

  .sign-popup input[type="password"] {
    width: calc(100% - 20px); /* Full width minus padding */
    padding: 10px;
    margin-bottom: 10px;
    border: 1.8px solid #4d4d4d; /* Light grey border */
    border-radius: 4px; /* Rounded corners */
  }
  
  .sign-up-button {
    width: calc(100% - 20px); /* Full width minus padding */
    display: flex;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 20px;
    margin: 5px 0;
    border: none;
    border-radius: 4px; /* Rounded corners */
    cursor: pointer;
    background-color: #3d3d3d; /* Black background */
    color: #fff; /* White text */
    font-size: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .sign-up-button img {
    margin-right: 10px;
    height: 30px;
    width: 30px;
  }

  .email-sign-up-button {
    width: calc(100% - 20px); /* Full width minus padding */
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    margin: 10px 0;
    border: none;
    border-radius: 4px; /* Rounded corners */
    cursor: pointer;
    background-color: #3d3d3d; /* Black background */
    color: #fff; /* White text */
    font-size: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .divider {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 20px 0;
  }
  
  .divider hr {
    flex: 1;
  }
  
  .terms, .switch-login {
    font-size: 0.8em;
    margin-top: 20px;
  }
  
  .terms a, .switch-login p {
    color: #4285F4;
    text-decoration: none;
  }
  
  .terms a:hover, .switch-login p:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .sign-exception {
    color: #ff0000;
    margin-top: 10px;
  }

@media screen and (max-width: 1050px) {

    .navbar-menu {
        display: flex;
    }

    .navbar-sign {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .navbar {
        padding: 2rem 4rem;
    }

    .navbar-links_container {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Creates 2 columns */
        gap: 10px; /* Adjust the gap between items */
        max-width: 500px;
    }
}

@media screen and (max-width: 550px) {

    .navbar-sign {
        display: flex;
        transform: translate(-190px, 140px);
        margin-bottom: 25px;
        max-width: 25px;
    }

    .navbar-menu_container {
        top:20px;
    }

    .navbar-menu_container-links-sign {
        display: block;
    }

    .navbar-sign button,
    .navbar-menu_container button {
      min-width: 95px;
    }

    .navbar-sign p {
      min-width: 58px;
    }
}
